<template><i></i></template>

<script>
import Painterro from "painterro";

export default {
  props: {
    image: {
      type: String
    },
  },
  created: function () {
    var self = this;

    var ptro = Painterro({
      hideByEsc: true,
      toolbarPosition: "top",
      saveHandler: function (temp_img, done) {
        self.$emit("done", { blob: temp_img.asBlob(), dataUrl: temp_img.asDataURL() });

        done(true);
      },
      onClose: function () {
        self.$emit("close");
      },
    });
    ptro.show(self.image);
  },
};
</script>

<style>

.ptro-holder-wrapper {
  z-index: 100000;
}
  </style>